// dependencies
import React from "react";
import dynamic from "next/dynamic";

// components
import ActivityIndicator from "@cloudspire/legacy-resources/src/components/ActivityIndicator";

// constants
import theming from "@cloudspire/legacy-resources/src/constants/theming";

// helpers
import {
  roomIsRankingCityBreak,
  roomIsRankingEpi,
  roomIsRankingValid,
} from "@cloudspire/legacy-resources/src/helpers/room";

const { useTheme } = theming;

type PropsType = {
  ranking: ReturnType<
    typeof import("@cloudspire/legacy-resources/src/normalizers/room").roomParseData
  >["ranking"];
  color?: string;
  aCitybreakStyle?;
};

/**
 * Use a instance storage in order to prevent blinking UI.
 */
const componentLoader = {
  clev: null,
  epi: null,
  citybreak: null,
  getClev() {
    if (null === componentLoader.clev) {
      componentLoader.clev = dynamic(() => import("./Clev"), {
        loading: () => <ActivityIndicator size={16 as any} />,
      });
    }

    return componentLoader.clev;
  },
  getEpi() {
    if (null === componentLoader.epi) {
      componentLoader.epi = dynamic(() => import("./Epi"), {
        loading: () => <ActivityIndicator size={16 as any} />,
      });
    }

    return componentLoader.epi;
  },
  getCitybreak() {
    if (null === componentLoader.citybreak) {
      componentLoader.citybreak = dynamic(() => import("./Citybreak"), {
        loading: () => <ActivityIndicator size={16 as any} />,
      });
    }

    return componentLoader.citybreak;
  },
};

const Ranking: React.FunctionComponent<PropsType> = (props) => {
  const { ranking, color, aCitybreakStyle } = props;

  const theme = useTheme();

  if (roomIsRankingEpi({ room: { ranking } } as any)) {
    if (ranking.value > 0) {
      switch (theme.NAME) {
        case "clev": {
          const Clev = componentLoader.getClev();

          return <Clev ranking={ranking} />;
        }

        case "gdf": {
          const Epi = componentLoader.getEpi();

          return <Epi ranking={ranking} color={color} />;
        }
      }
    }
  } else if (
    roomIsRankingCityBreak({ room: { ranking } }) &&
    roomIsRankingValid({ room: { ranking } })
  ) {
    const Citybreak = componentLoader.getCitybreak();

    return <Citybreak aStyle={aCitybreakStyle} ranking={ranking} />;
  }

  return null;
};

export default Ranking;
