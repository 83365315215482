// dependencies
import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { StyleSheet, css } from "aphrodite";

// components
import {
  List,
  ListItem,
} from "@cloudspire/legacy-resources/src/components/List";
import Rate from "@cloudspire/legacy-resources/src/components/Rate";
import BaseComponent from "@cloudspire/legacy-resources/src/components/BaseComponent2";

const styles = StyleSheet.create({
  reviews: {
    fontSize: "0.75rem",
  },
});

function RoomRateAndReviews(props) {
  const { rate, reviews, long = false } = props;

  const intl = useIntl();

  return (
    <BaseComponent>
      <List gutter={1} inline={true} style={{ alignItems: "center" }}>
        <ListItem>
          <Rate rate={rate} />
        </ListItem>

        <ListItem>
          <div className={css(styles.reviews)}>
            {intl.formatMessage(
              { id: "app.constants.room.reviews" },
              { reviews, long }
            )}
          </div>
        </ListItem>
      </List>
    </BaseComponent>
  );
}

RoomRateAndReviews.propTypes = {
  rate: PropTypes.number,
  reviews: PropTypes.number,
  long: PropTypes.bool,
};

export default RoomRateAndReviews;
