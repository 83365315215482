// dependencies
import React, { useState, useRef, useCallback, useEffect } from "react";
import { View, StyleProp, StyleSheet, ViewStyle } from "react-native";

// components
import Text from "@cloudspire/legacy-resources/src/components/Text";
import Popover from "@cloudspire/legacy-resources/src/components/Popover2";
import { IconNotificationQuestionO } from "@cloudspire/legacy-svg-icon-library";

// constants
import theming from "@cloudspire/legacy-resources/src/constants/theming";

// hocs
import useHover from "@cloudspire/legacy-resources/src/hocs/useHover";

// libraries
import { emToPx } from "@cloudspire/legacy-resources/src/libraries";

const { useTheme } = theming;

type PropsType = {
  message: React.ReactNode;
  style?: StyleProp<ViewStyle>;
  mainStyle?: StyleProp<ViewStyle>;
};

const styles = StyleSheet.create({
  icon: {
    lineHeight: 0,
    cursor: "help",
  },
  messageContainer: {
    padding: emToPx(0.5),
    borderRadius: emToPx(0.25),
  },
  message: {
    color: "#ffffff",
    fontSize: emToPx(0.75),
  },
});

const Tooltip: React.FunctionComponent<PropsType> = (props) => {
  const { message, style, mainStyle, children } = props;

  const theme = useTheme();

  const { hovered, onMouseEnter, onMouseLeave } = useHover();

  const $tooltip = useRef<View>();
  const [rect, setRect] = useState(null);

  const handleLayout = useCallback(() => {
    if ($tooltip.current) {
      $tooltip.current.measureInWindow((x, y, width, height) =>
        setRect({ x, y, width, height })
      );
    }
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleLayout);

    return () => {
      window.removeEventListener("scroll", handleLayout);
    };
  }, [handleLayout]);

  return (
    <>
      <View
        onLayout={handleLayout}
        ref={$tooltip}
        style={mainStyle}
        {...{
          onMouseEnter,
          onMouseLeave,
        }}
      >
        {undefined !== children ? (
          children
        ) : (
          <Text style={styles.icon}>
            <IconNotificationQuestionO />
          </Text>
        )}
      </View>

      <Popover visible={hovered} rect={rect} gutter={0.5}>
        <View
          style={[
            styles.messageContainer,
            { backgroundColor: theme.PRIMARY_COLOR_TEXT },
            style,
          ]}
        >
          <Text style={styles.message}>{message}</Text>
        </View>
      </Popover>
    </>
  );
};

export default Tooltip;
