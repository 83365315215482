// dependencies
import React from "react";
import { StyleSheet, css } from "aphrodite";
import { useIntl } from "react-intl";

// components
import BaseComponent from "@cloudspire/legacy-resources/src/components/BaseComponent2";
import Star from "./Star";

// constants
import { STAR_COUNT } from "@cloudspire/legacy-resources/src/constants/rate";

type PropsType = {
  rate: number;
};

const styles = StyleSheet.create({
  icons: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    color: "#FFDF2F",
    fontSize: "1em",
  },
});

const Rate: React.FunctionComponent<PropsType> = (props) => {
  const { rate } = props;

  const intl = useIntl();

  const rates = [];
  const roundedRate = Math.round(rate);

  for (let i = 1; i <= STAR_COUNT; i++) {
    if (i > roundedRate) {
      rates.push(
        <Star
          key={i}
          type={"empty"}
          grownedUp={false}
          last={i === STAR_COUNT}
        />
      );
    } else if (i === roundedRate) {
      // si l'étoile courante est la dernière étoile remplie (complète ou à moitié)
      // comme `i` commence à `1`, on est sûr que l'on ne mettra pas en avant une étoile vide (dans le cas où le ratio est < 0.5)

      rates.push(
        <Star
          key={i}
          // si le ratio est compris entre `[n + 0.5, n + 1[`
          type={rate - roundedRate >= 0 ? "full" : "half"}
          grownedUp={true}
          last={i === STAR_COUNT}
        />
      );
    } else {
      rates.push(
        <Star key={i} type={"full"} grownedUp={false} last={i === STAR_COUNT} />
      );
    }
  }

  const label = intl.formatMessage(
    {
      id: "app.component.rate.label",
    },
    { rate }
  );

  return (
    <BaseComponent>
      <div className={css(styles.icons)} title={label}>
        {rates}
      </div>
    </BaseComponent>
  );
};

export default Rate;
